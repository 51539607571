import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { get_meeting_list, post_meeting_list } from "../../api/index";
import tinymce from 'tinymce/tinymce'; // tinymce默认hidden，不引入不显示

import Editor from '@tinymce/tinymce-vue';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/icons/default/icons';
import 'tinymce/icons/default/icons.min.js';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
export default {
  name: "basetable",
  components: {
    Editor: Editor
  },
  data: function data() {
    var _this = this;

    return {
      value2: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      hoteldatares: {
        id: "",
        keyword: this.keyword,
        // 搜索
        page_index: 1,
        page_size: 5
      },
      addform: {
        type: "add",
        name: this.name,
        address: this.address,
        area: this.area,
        hold_count: this.hold_count,
        work_price: this.work_price,
        holiday_price: this.holiday_price,
        desc: this.desc,
        open_time: [1],
        image_url: []
      },
      editform: {
        type: "edit",
        name: this.name,
        address: this.address,
        hold_count: this.hold_count,
        area: this.area,
        work_price: this.work_price,
        holiday_price: this.holiday_price,
        desc: this.desc,
        open_time: [1],
        image_url: []
      },
      query: {
        address: "",
        name: ""
      },
      rules: {
        name: [{
          required: true,
          message: "请输入会议室名称",
          trigger: "blur"
        }],
        address: [{
          required: true,
          message: "请输入会议室地址",
          trigger: "blur"
        }],
        hold_count: [{
          required: true,
          message: "请输入会议可容纳人数",
          trigger: "blur"
        }],
        work_price: [{
          required: true,
          message: "请输入工作日价格",
          trigger: "blur"
        }],
        holiday_price: [{
          required: true,
          message: "请输入节假日价格",
          trigger: "blur"
        }],
        area: [{
          required: true,
          message: "请输入会议室面积",
          trigger: "blur"
        }],
        desc: [{
          required: true,
          message: "请输入会议室属性  ",
          trigger: "blur"
        }],
        open_time: [{
          required: true,
          message: "请选择开放时间  ",
          trigger: "blur"
        }]
      },
      // tinymceHtml: ` `,
      init: {
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/lightgray',
        height: 300,
        plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
        toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
        branding: false,
        paste_word_valid_elements: '*[*]',
        // word需要它
        paste_data_images: true,
        // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false,
        // 插入word文档需要该属性
        // 配置图片上传的功能
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          _this.handleImgUpload(blobInfo, success, failure);
        }
      },
      tableData: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      permissionBeans: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this2 = this;

      get_meeting_list(this.hoteldatares).then(function (res) {
        _this2.tableData = res.data.list;
        _this2.pageTotal = res.data.count;
      });
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        console.log(row);
        var data = {
          id: row.id,
          type: "delete"
        };
        post_meeting_list(data).then(function (res) {
          if (res.code == 1) {
            console.log(res);

            _this3.$message.success("删除成功");

            _this3.tableData.splice(index, 1);

            _this3.getData();
          } else {
            _this3.$message.error(res.msg);
          }
        });
      }).catch(function () {});
    },
    addmenu: function addmenu() {
      this.addVisible = true;
    },
    //上传图片
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.loading = false;
      this.addform.image_url.push(res.data.filepath);

      if (res.code == 1) {
        this.$message.success(res.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this4 = this;

      this.loading = true;
      this.progressData = 0;
      var timer2 = setInterval(function () {
        _this4.progressData = _this4.progressData + 1;

        if (_this4.progressData >= 100) {
          clearInterval(timer2);
        }
      }, 200);
      var isJPG = file.type === 'image/jpeg/jpg';
      var isLt5M = file.size / 1024 / 1024 < 10; // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
      // }
      // if (!isLt5M) {
      //   this.$message.error("上传头像图片大小不能超过 10MB!");
      // }

      return isLt5M;
    },
    // 添加保存
    addmenuFun: function addmenuFun(addform) {
      var _this5 = this;

      console.log(this.addform);
      this.$refs[addform].validate(function (valid) {
        if (valid) {
          post_meeting_list(_this5.addform).then(function (res) {
            if (res.code == 1) {
              _this5.addVisible = false;

              _this5.$message.success(res.msg);

              _this5.getData();
            } else {
              console.log(res);

              _this5.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      this.idx = index;
      this.editform.id = row.id;
      this.editform.name = row.name;
      this.editform.address = row.address;
      this.editform.area = row.area;
      this.editform.hold_count = row.hold_count;
      this.editform.work_price = row.work_price = this.editform.holiday_price = row.holiday_price;
      this.editform.desc = row.desc;
      this.editform.image_url = row.image_url;
      this.editVisible = true;
    },
    addsmall: function addsmall() {
      this.$message.success("\u6DFB\u52A0\u5B50\u83DC\u5355");
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file); // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      var _this6 = this;

      post_meeting_list(this.editform).then(function (res) {
        if (res.code == 1) {
          _this6.editVisible = false;

          _this6.$message.success(res.msg);

          _this6.getData();
        } else {
          _this6.$message.error(res.msg);
        }
      });
    },
    // 分页导航
    handleCurrentChange: function handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index); //点击第几页

      this.getData();
    },
    to_data: function to_data() {
      console.log(this.tinymceHtml);
      var Base64 = {
        encode: function encode(str) {
          return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
          }));
        },
        decode: function decode(str) {
          return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
        }
      }; // 将富文本内容专程base64编码，这个用于上传到服务存储到数据库中

      var encoded = Base64.encode(this.addhotelData.desc); // 将富文本的base64编码 转换成原来的格式，这个用于将数据库中的富文本展示在界面上

      var decoded = Base64.decode(encoded);
      console.log(encoded);
      console.log(decoded);
    },
    // 用于上传图片的，后端需要提供好上传接口
    handleImgUpload: function handleImgUpload(blobInfo, success, failure) {
      var formdata = new FormData();
      formdata.set('upload_file', blobInfo.blob());
      var new_headers = {
        headers: this.headers
      };
      var upload_url = process.env.BASE_API + '/website/uploadfile';
      axios.post(upload_url, formdata, new_headers).then(function (res) {
        // console.log(res.data.data)
        success(res.data.data[0]);
      }).catch(function (res) {
        failure('error');
      });
    }
  },
  mounted: function mounted() {
    tinymce.init({});
  }
};